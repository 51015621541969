import { ComponentImporterFunction } from 'phputils-js/src/react_/AppRegistry';

const rootComponents: {
    [componentName: string]: ComponentImporterFunction,
} = {
    stageTasks: () => (import('./pages/tasks/StageTasks')),
    applicationsPage: () => (import('./pages/applications/ApplicationsPage')),
    archivedApplicationsPage: () => (import('./pages/archived-applications/ArchivedApplicationsPage')),
    archivedEntitiesPage: () => (import('./pages/entity/archived/ArchivedEntitiesPage')),
    deletedApplicationsPage: () => (import('./pages/deleted-applications/DeletedFoldersPage')),
    workspaceDashboard: () => (import('./pages/workspaces/WorkspaceDashboardPage')),
    workspaceArchived: () => (import('./pages/archived-workspaces/ArchivedWorkspacesPage')),
    workspaceDeleted: () => (import('./pages/deleted-workspaces/DeletedApplicationsPage')),
    navigationHeader: () => (import('./pages/navigation/Header')),
    homePage: () => (import('./pages/home/Home')),
    profilePage: () => (import('./pages/profile/ProfilePage')),
    myRecords: () => (import('./pages/my-records/MyRecords')),
    personRecords: () => (import('./pages/person-records/PersonRecords')),
    personApplications: () => (import('./pages/person-applications/PersonApplicationsPage')),
    personWorkspace: () => (import('./pages/person-folders/PersonFoldersPage')),
    personFolders: () => (import('./pages/person-folders/PersonFoldersPage')),
    personBoards: () => (import('./pages/person-boards/PersonBoardsPage')),
    allBoards: () => (import('./pages/all-boards/AllBoards')),
    board: () => (import('./pages/board/Board')),
    organisationsPage: () => (import('./pages/entity/organisations/OrganisationsMainPage')),
    peoplePage: () => (import('./pages/entity/people/PeoplePage')),
    browseAllPage: () => (import('./pages/entity/browse-all-entities/BrowseAllEntitiesPage')),
    clientsPage: () => (import('./pages/entity/clients-entities/AllClientsPage')),
    subcontractorsPage: () => (import('./pages/entity/subcontractors-entities/AllSubcontractorsPage')),
    suppliersPage: () => (import('./pages/entity/suppliers-entities/AllSuppliersPage')),
    entityPage: () => (import('./pages/entity/EntityPage')),
    loginPage: () => (import('./pages/login/Login')),
    forgotPasswordPage: () => (import('./pages/login/ForgotPassword')),
    resetPasswordPage: () => (import('./pages/login/ResetPassword')),
    activatePage: () => (import('./pages/login/ActivateUser')),
    applicationDashboardPage: () => (import('./pages/application-dashboard')),
    applicationTypesPage: () => (import('./pages/application-app-types/ApplicationTypesPage')),
    applicationTypeOptionsPage: () => (import('./pages/application-app-types/ApplicationTypeOptions')),
    projectOptionsPage: () => (import('./pages/project-options/projectOptionsPage')),
    groupsListPage: () => (import('./pages/groups/GroupsList')),
    applicationMembersPage: () => (import('./pages/application-members')),
};

export default rootComponents;
